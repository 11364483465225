import React from "react";
import { Grid } from "@mui/material";
import ArticleGridItem from "../components/articleGridItem";
import { SubHeadline } from "../styles/globalStyles";

const RelatedArticlesComponent = (props) => {
  const article = props.article;
  const allArticles = props.allArticles.nodes.sort((a, b) => a.date > b.date);

  let relatedArticles = [];

  for (let articleIndex in allArticles) {
    let maybeRelatedArticle = allArticles[articleIndex];
    let highscore = 0;
    for (const [index, tag] of article.frontmatter.tags.entries()) {
      for (let maybeTag of maybeRelatedArticle.frontmatter.tags) {
        if (tag === maybeTag) {
          highscore += 100 / (index + 1);
        }
      }
    }
    if (highscore !== 0 && maybeRelatedArticle.slug !== article.slug) {
      relatedArticles.push({
        article: maybeRelatedArticle,
        highscore: highscore,
      });
    }
  }

  relatedArticles.sort((a, b) => b.highscore - a.highscore);
  relatedArticles = relatedArticles.slice(0, 6);

  if (relatedArticles.length > 0) {
    return (
      <div>
        <SubHeadline>Articles with related topics</SubHeadline>
        <Grid container spacing={1}>
          {relatedArticles.map((node) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={node.article.id}>
                <ArticleGridItem article={node.article}></ArticleGridItem>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
  return null;
};

export default RelatedArticlesComponent;
