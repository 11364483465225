import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import pdfDownload from "../../content/resources/img/combineOperatorsPdf/CombineOperatorsCheatSheet.pdf";
import { colors } from "../styles/globalStyles";

const CombineOperators = (props) => {
  const type = props.type;

  const data = useStaticQuery(graphql`
    query {
      tanaschitaLogo: file(
        relativePath: { eq: "img/logo/tanaschitaLogo.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 60)
        }
      }
      combineOperators: allFile(
        filter: { relativeDirectory: { eq: "img/combineOperators" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImageSharp {
              logo: gatsbyImageData(width: 1500)
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <Grid container spacing={1} margin={5}>
        {combineOperatorsDataArray.map((gridData) => {
          let image = findImage(
            gridData.imageName,
            data.combineOperators.edges
          );
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={3.6}
              key={gridData.imageName}
              margin={1}
            >
              <GridImageTitle>{gridData.title}</GridImageTitle>
              <GridItemDescription height={75}>
                {gridData.description}
              </GridItemDescription>
              <GatsbyImage
                image={image.node.childImageSharp.logo}
                alt="Combine operator"
              />
            </Grid>
          );
        })}
      </Grid>
      <a href={pdfDownload} target="_blank" style={{ textDecoration: "none" }}>
        <PreviewBookButtonContainer>
          Download cheat sheet as pdf
        </PreviewBookButtonContainer>
      </a>
    </div>
  );
};

const GridImageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "22px",
  lineHeight: "1.3",
  marginBottom: "8px",
  [theme.breakpoints.down("xs")]: {
    fontSize: "18px",
  },
}));

const GridItemDescription = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "1.1",
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
  },
}));

function findImage(imageName, allImages) {
  return allImages.find((x) => {
    let path = x.node.childImageSharp.fluid.src;
    let name = path.substring(path.lastIndexOf("/") + 1);
    return name === imageName;
  });
}

const combineOperatorsDataArray = [
  {
    title: "allSatisfy(_:)",
    description:
      "Publishes true if all elements match the condition. Publishes false & completes if it receives an element that does not match the condition.",
    imageName: "allSatisfyTrue.png",
  },
  {
    title: "append(_:)",
    description:
      "Appends the specified elements after the upstream publisher completes.",
    imageName: "append.png",
  },
  {
    title: "collect(_:)",
    description:
      "Collects up to the specified number of elements and emits them in an array.",
    imageName: "collect.png",
  },
  {
    title: "combineLatest(_:)",
    description:
      "Publishes a tuple of the most recent element from multiple publishers when any of them emits a value.",
    imageName: "combineLatest.png",
  },
  {
    title: "compactMap(_:)",
    description:
      "Transforms each element with the provided closure and publishes any returned non-nil optional result.",
    imageName: "compactMap.png",
  },
  {
    title: "contains(_:)",
    description:
      "Publishes true & completes after receiving a matching value, or false when upstream publisher completes without producing a matching value.",
    imageName: "contains.png",
  },
  {
    title: "count()",
    description:
      "Publishes the count of all received elements when the upstream publisher completes.",
    imageName: "count.png",
  },
  {
    title: "dropFirst(_:)",
    description:
      "Omits a specified number of elements, then republishes all remaining elements.",
    imageName: "dropFirst.png",
  },
  {
    title: "drop(while:)",
    description:
      "Omits elements until a given closure returns false, then republishes all remaining elements.",
    imageName: "dropWhile.png",
  },
  {
    title: "filter(_:)",
    description: "Republishes all elements that match a provided closure.",
    imageName: "filter.png",
  },
  {
    title: "first()",
    description:
      "Publishes the first element of the upstream publisher, then completes.",
    imageName: "first.png",
  },
  {
    title: "first(where:)",
    description:
      "Publishes the first element of a stream that satisfies a condition, then completes.",
    imageName: "firstWhere.png",
  },
  {
    title: "flatMap(maxPublishers:_:)",
    description:
      "Transforms all elements from an upstream publisher into a new publisher.",
    imageName: "flatMap.png",
  },
  {
    title: "ignoreOutput()",
    description:
      "Ignores all elements and passes along only the upstream publisher's completion state.",
    imageName: "ignoreOutput.png",
  },
  {
    title: "last()",
    description:
      "Publishes the last element of the upstream publisher after it completes.",
    imageName: "last.png",
  },
  {
    title: "last(where:)",
    description:
      "Publishes the last element that satisfies a condition after the upstream publisher completes.",
    imageName: "lastWhere.png",
  },
  {
    title: "map(_:)",
    description:
      "Transforms each element it receives from the upstream publisher with the provided closure.",
    imageName: "map.png",
  },
  {
    title: "max()",
    description:
      "Publishes the maximum value received after the upstream publisher completes.",
    imageName: "max.png",
  },
  {
    title: "merge(with:)",
    description:
      "Publishes an element whenever any of the upstream publishers emits an element.",
    imageName: "merge.png",
  },
  {
    title: "min()",
    description:
      "Publishes the minimum value received after the upstream publisher completes.",
    imageName: "min.png",
  },
  {
    title: "output(at:)",
    description:
      "Republishes an element specified by its position, or completes if upstream publisher completes before the specified element.",
    imageName: "output.png",
  },
  {
    title: "output(in:)",
    description: "Republishes elements specified by a range of positions.",
    imageName: "outputRange.png",
  },
  {
    title: "prefix(_:)",
    description: "Republishes elements up to a specified maximum count.",
    imageName: "prefix.png",
  },
  {
    title: "prefix(while:)",
    description:
      "Emits values while elements meet the specified condition, otherwise it completes.",
    imageName: "prefixWhile.png",
  },
  {
    title: "prepend(_:)",
    description: "Prepends elements with the specified values.",
    imageName: "prepend.png",
  },
  {
    title: "reduce(_:_:)",
    description:
      "Applies a closure to all elements and publishes a final result when the upstream publisher completes.",
    imageName: "reduce.png",
  },
  {
    title: "removeDuplicates()",
    description:
      "Publishes an element only if it doesn't match the previous element.",
    imageName: "removeDuplicates.png",
  },
  {
    title: "replaceNil(with:)",
    description: "Replaces nil elements with the provided element.",
    imageName: "replaceNil.png",
  },
  {
    title: "scan(_:_:)",
    description:
      "Transforms elements by applying a closure that receives its previous return value and the next element from the upstream publisher.",
    imageName: "scan.png",
  },
  {
    title: "switchToLatest()",
    description:
      "Republishes elements sent by the most recently received publisher.",
    imageName: "switchToLatest.png",
  },
  {
    title: "zip(_:)",
    description:
      "Waits until both publishers emit an element, then publishes the oldest unconsumed element from each one as a tuple.",
    imageName: "zip.png",
  },
];

const PreviewBookButtonContainer = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: colors.primaryColor,
  fontWeight: "600",
  paddingRight: "15px",
  paddingBottom: "5px",
  display: "inline-block",
}));

export default CombineOperators;
